import { useAuth } from "@Context/Auth.context";
import { useThemeType } from "@Context/ThemeType.context";
import { getThemeVideo } from "LocaleBased";
import { Fragment, ReactElement } from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

interface Props {
  poster?: string;
}

export const VideoContainer = styled.div`
  height: calc(100vh + 28px); /* 28px is for next section radius */
`;

export const Video = styled(ReactPlayer as any)`
  width: 100% !important;
  height: 100% !important;
  video {
    object-fit: cover;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
  }
`;

export default function VideoComponent({ poster }: Props): ReactElement {
  const { localIpInfo } = useAuth();
  const { themeType } = useThemeType();
  const videoUrl = getThemeVideo(localIpInfo?.country_code, themeType);

  const isKSAFoundingDay2025 = (): boolean => {
    const currentDate = new Date();
    const startDate = new Date("2025-01-01");
    const endDate = new Date("2025-02-28");
    return (
      currentDate >= startDate && currentDate <= endDate
      // (localIpInfo?.country_code || "").toLocaleLowerCase() === "sa"
    );
  };

  return (
    <Fragment>
      <VideoContainer>
        <Video
          url={isKSAFoundingDay2025() ? "/videos/HeroArea/baims-sa2.mp4" : videoUrl}
          loop
          playing
          muted
          playsinline
        ></Video>
      </VideoContainer>
    </Fragment>
  );
}
