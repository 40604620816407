export const COOKIE_LANG = "NEXT_LOCALE";

export const HAS_PASSWORD = "HAS_PASSWORD";

export const COOKIE_AUTH = "BAIMS_TOKEN";
export const COOKIE_AUTH_REFRESH = "BAIMS_REFRESH_TOKEN";

export const COMPLETED_PROFILE = "completed_profile";
export const LOCALSTORAGE_IP_INFO = "BAIMS_IP_INFORMATIONS";

export const USER_DEVICE_ID = "USER_DEVICE_ID";
export const MAIN_LAYOUT_WRAPPER_ID = "MAIN_LAYOUT_WRAPPER_ID";
export const DEFAULT_COOKIE_DOMAIN_NAME = process.env.NEXT_PUBLIC_COOKIE_DOMAIN_NAME;

// we use this Constant with countryCode to apply Country Theme
export const KSA_COUNTRY_CODE = "remove-ksa-theme"; // to back to default theme change value to "remove-ksa-theme"

export const REGULAR_THEME = "REGULAR_THEME";
export const NATIONAL_DAY_THEME = "NATIONAL_DAY_THEME";
export const FOUNDING_DAY_THEME = "FOUNDING_DAY_THEME";

export const VIDEO_IS_PLAYING = "VIDEO_IS_PLAYING";
export const VIDEO_IS_PAUSED = "VIDEO_IS_PAUSED";

export const SECTION_ONE = "SECTION_ONE";
export const SECTION_TWO = "SECTION_TWO";
export const SECTION_THREE = "SECTION_THREE";

export const ZAIN_PHONE_VIEW = "ZAIN_PHONE_VIEW";
export const ZAIN_CIVIL_VIEW = "ZAIN_CIVIL_VIEW";
export const ZAIN_OTP_VIEW = "ZAIN_OTP_VIEW";

export const SLIDER_ISBEGINNING = "SLIDER_ISBEGINNING";
export const SLIDER_ISENDING = "SLIDER_ISENDING";
// export const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
// export const phonePattern = /^\d{0,13}$/i;
// export const websitePattern =
//   /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i;
/**
 * Instagram username Pattern
 * - Minimum 3 Chars, Maximum 30 Chars
 * - Accepts only _ and .  not squentially
 * - can't start or end with ., but can start or end with _
 */
export const usernamePattern = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{3,29}$/i;
/**
 * Default Cookie Settings
 */

export const DEFAULT_COOKIE_DOMAIN_SETTINGS = {
  domain: DEFAULT_COOKIE_DOMAIN_NAME,
  expires: 30 * 12,
};

export const DEFAULT_COOKIE_SETTINGS = {
  path: "/",
  domain: DEFAULT_COOKIE_DOMAIN_NAME,
};

export const CountriesList = [
  {
    name: "Kuwait",
    subdomain: "kw",
    subroute: "kw",
    algolia_key: "kw",
    emoji: "🇰🇼",
  },
  {
    name: "Saudi Arabia",
    subdomain: "ksa",
    subroute: "sa",
    algolia_key: "ksa",
    emoji: "🇸🇦",
  },
  {
    name: "Bahrain",
    subdomain: "bahrain",
    subroute: "bh",
    algolia_key: "bahrain",
    emoji: "🇧🇭",
  },
  {
    name: "Jordan",
    subdomain: "jordan",
    subroute: "jo",
    algolia_key: "jordan",
    emoji: "🇯🇴",
  },
] as const;

export const LocalesList = ["", "ar"] as const; // empty string === "en"

export const DateFormat = "yyyy-MM-dd";

export const USER_DATA_COOKIE_NAME = "X-USER-DATA";
