"use client";

import { useState, useEffect } from "react";
import { Wrapper, InnerContainer, Text, TimeBox, TimeContainer } from "./CountDownBar.styled";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { useRouter } from "next/router";

type CountdownProps = {
  targetDate: Date;
  placeholder: any;
  isKSAUser: boolean;
};

export function CountDownBar({ targetDate = new Date("2024-10-31T23:59:59"), placeholder, isKSAUser }: CountdownProps) {
  const { locale } = useRouter();
  const [timeLeft, setTimeLeft] = useState<{ [key: string]: number }>({});
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    function calculateTimeLeft() {
      const difference = +targetDate - +new Date();
      let newTimeLeft: { [key: string]: number } = {};

      if (difference > 0) {
        newTimeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }

      return newTimeLeft;
    }

    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      if (Object.keys(newTimeLeft).length === 0) {
        setIsVisible(false);
        clearInterval(timer);
      } else {
        if (isKSAUser) {
          setIsVisible(true);
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const addLeadingZero = (value: number) => {
    return value < 10 ? `0${value}` : value;
  };

  if (!isVisible) {
    return placeholder;
  }

  const couponText =
    locale === "ar"
      ? "بمناسبة يوم التأسيس احصل على خصم 22% على مادة أو الشابتر استخدم كود"
      : "In celebration of Foundation Day, enjoy a 22% discount on any Subject or Course. Use the code:";

  return (
    <Wrapper dir="rtl">
      <Container>
        <Row>
          <Col col={12}>
            <InnerContainer>
              <Text dir="auto">
                {couponText} <span dir="ltr">saudi22</span>
              </Text>
              <TimeContainer>
                {timeLeft.days > 0 && (
                  <>
                    <TimeBox>
                      <div>{addLeadingZero(timeLeft.days)}</div>
                    </TimeBox>
                    <div>:</div>
                  </>
                )}
                {(timeLeft.days > 0 || timeLeft.hours > 0) && (
                  <>
                    <TimeBox>
                      <div>{addLeadingZero(timeLeft.hours)}</div>
                    </TimeBox>
                    <div>:</div>
                  </>
                )}
                {(timeLeft.days > 0 || timeLeft.hours > 0 || timeLeft.minutes > 0) && (
                  <>
                    <TimeBox>
                      <div>{addLeadingZero(timeLeft.minutes)}</div>
                    </TimeBox>
                    <div>:</div>
                  </>
                )}
                {(timeLeft.days > 0 || timeLeft.hours > 0 || timeLeft.minutes > 0 || timeLeft.seconds > 0) && (
                  <>
                    <TimeBox>
                      <div>{addLeadingZero(timeLeft.seconds)}</div>
                    </TimeBox>
                  </>
                )}
              </TimeContainer>
            </InnerContainer>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}
